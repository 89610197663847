//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FacebookLogo from '~/assets/img/icons/FacebookLogo';
import YoutubeLogo from '~/assets/img/icons/YoutubeLogo';
import InstagramLogo from '~/assets/img/icons/InstagramLogo';
import TiktokLogo from '~/assets/img/icons/TiktokLogo';
import SpotifyLogo from '~/assets/img/icons/SpotifyLogo';
import moment from 'moment';
import { mapState } from 'vuex';

export default {
	components: { SpotifyLogo, TiktokLogo, InstagramLogo, YoutubeLogo, FacebookLogo },

	async fetch () {
		try {
			const response = await this.$axios.get('/contact');
			await this.$store.dispatch('footer/fetchContacts', response.data.payload);
		}
		catch (e) {
			this.$nuxt.error({
				statusCode: 404,
				message: 'Unable to fetch contacts posts at this time. Please try again.',
			});
		}

		try {
			const response = await this.$axios.get('/promos');
			await this.$store.dispatch('footer/fetchPromos', response.data.payload);
		}
		catch (e) {
			this.$nuxt.error({
				statusCode: 404,
				message: 'Unable to fetch promos posts at this time. Please try again.',
			});
		}

		try {
			const response = await this.$axios.get('/social-medias');
			await this.$store.dispatch('footer/fetchSocialMedias', response.data.payload);
		}
		catch (e) {
			this.$nuxt.error({
				statusCode: 404,
				message: 'Unable to fetch social medias posts at this time. Please try again.',
			});
		}
	},

	computed: mapState({
		contacts: (state) => state.footer.contacts,
		promos: (state) => state.footer.promos,
		socialMedias: (state) => state.footer.socialMedias,
	}),

	methods: {
		moment: function () {
			return moment();
		},

		onLogoClick () {
			if (this.$router.currentRoute.fullPath !== '/') {
				this.$router.push('/');
			}
			else {
				window.scrollTo(0, 10);
			}

			this.$store.dispatch('footer/fetchShowNavbar', true);
		},
	},
};

export default () => {
	(function () {

		function liveEventTrack (eventName, selector, eventCallback, capture) {
			capture = capture || false;
			document.addEventListener(eventName, function (e) {
				if (e.target.matches(selector + ', ' + selector + ' *')) {
					eventCallback.apply(e.target.closest(selector), arguments);
				}
			}, capture);
		}

		liveEventTrack('click', '[data-gtm-push]', function () {
			var _this = this;
			// console.log('data-gtm-push found on:', _this);

			if (!Array.isArray(window.dataLayer)) {
				// console.log('dataLayer not an array, quitting..');
				return;
			}

			var parsedJson = JSON.parse(_this.dataset.gtmPush);
			// console.log('data to push:', parsedJson);

			window.dataLayer.push(parsedJson);
			// console.log('pushed to dataLayer array');
			// console.log('dataLayer:', window.dataLayer);
		}, false);
	})();
};

/* eslint no-shadow: ["error", { "allow": ["state"] }]*/

export const state = () => ({
	contacts: [],
	promos: [],
	socialMedias: [],
	showNavbar: true,
});

export const mutations = {
	SET_CONTACTS (state, contacts) {
		state.contacts = contacts;
	},

	SET_PROMOS (state, promos) {
		state.promos = promos;
	},

	SET_SOCIAL_MEDIAS (state, socialMedias) {
		state.socialMedias = socialMedias;
	},

	SET_SHOW_NAVBAR (state, showNavbar) {
		state.showNavbar = showNavbar;
	},
};

export const actions = {
	fetchContacts ({ commit }, contacts) {
		commit('SET_CONTACTS', contacts);
	},

	fetchPromos ({ commit }, promos) {
		commit('SET_PROMOS', promos);
	},

	fetchSocialMedias ({ commit }, socialMedias) {
		commit('SET_SOCIAL_MEDIAS', socialMedias);
	},

	fetchShowNavbar ({ commit }, showNavbar) {
		commit('SET_SHOW_NAVBAR', showNavbar);
	},
};

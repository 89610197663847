/* eslint no-shadow: ["error", { "allow": ["state"] }]*/

export const state = () => ({
	products: [],
});

export const getters = {
	products: state => state.products,
};

export const mutations = {
	setProducts (state, products) {
		state.products = products;
	},
};

export const actions = {
	getProducts ({ commit }) {
		return this.$axios.get('/products')
			.then(resp => {
				commit('setProducts', resp.data.payload);
				return resp;
			});
	},
};

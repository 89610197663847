/* eslint no-shadow: ["error", { "allow": ["state"] }]*/

export const state = () => ({
	partyThemes: [],
	partyTheme: [],
	partyThemeItems: [],
});

// export const getters = {
// 	myPrizes: state => state.myPrizes,
// 	anyPrizesWon: state => state.myPrizes.some(prize => prize.count !== 0),
// };

export const mutations = {
	SET_PARTY_THEMES (state, payload) {
		state.partyThemes = payload;
	},

	SET_PARTY_THEME (state, payload) {
		state.partyTheme = payload;
	},

	SET_PARTY_THEME_ITEMS (state, payload) {
		state.partyThemeItems = payload;
	},
};

// export const actions = {
// 	getMyPrizes({ commit }) {
// 		return this.$axios.get('/me/winnings').then(resp => {
// 			commit('setMyPrizes', resp.data.payload);
// 			return resp;
// 		});
// 	},

// 	updateFieldPassword({ commit }, { fieldName, updatedValue }) {
// 		commit('UPDATE_FIELD_PASSWORD', { fieldName, updatedValue });
// 	},
// };

/* eslint no-shadow: ["error", { "allow": ["state"] }]*/

export const state = () => ({
	blogs: [],
	blog: [],
	tips: [],
	tip: [],
	home: [],
});

export const mutations = {
	SET_BLOGS_BLOGS (state, allBlogs) {
		state.blogs = allBlogs;
	},

	SET_BLOGS_BLOG (state, slugBlog) {
		state.blog = slugBlog;
	},

	SET_BLOGS_TIPS (state, allTips) {
		state.tips = allTips;
	},

	SET_BLOGS_TIP (state, slugTip) {
		state.tip = slugTip;
	},

	SET_BLOGS_HOME (state, home) {
		state.home = home;
	},
};

export const actions = {
	fetchBlogsBlogs ({ commit }, allBlogs) {
		commit('SET_BLOGS_BLOGS', allBlogs);
	},

	fetchBlogsBlog ({ commit }, slugBlog) {
		commit('SET_BLOGS_BLOG', slugBlog);
	},

	fetchBlogsTips ({ commit }, allTips) {
		commit('SET_BLOGS_TIPS', allTips);
	},

	fetchBlogsTip ({ commit }, slugTip) {
		commit('SET_BLOGS_TIP', slugTip);
	},

	fetchBlogsHome ({ commit }, home) {
		commit('SET_BLOGS_HOME', home);
	},

};

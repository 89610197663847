

export default function(to, from) {
      if (to.hash) {
        return {
          selector: to.hash,
          behavior: 'smooth' };
      } else
      if (!from.fullPath.includes('prichute') && !to.fullPath.includes('slide')) {
        return { x: 0, y: 0 };
      }
    }

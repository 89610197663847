//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { gtmGlobal } from '~/helpers/gtm';

export default {
	data () {
		return {
			showCookiesLine: true,
		};
	},

	// async fetch () {
	// 	// console.log(this.$config.gtm[this.$i18n.locale]);
	// 	if (this.$cookies.get('cookies-gtm') === true) {
	// 		this.$gtm.init(this.$config.gtm[this.$i18n.locale]);
	// 	}
	// },

	methods: {
		cookiesPage () {
			this.showCookiesLine = false;
			setTimeout(() => {
				document.getElementById('settings-cookies').scrollIntoView();
			}, '100');
		},

		cookiesAcceptAll () {
			this.showCookiesLine = false;
			this.$cookies.set('cookies-gtm', 1);
			gtmGlobal({ $gtm: this.$gtm, $config: this.$config, locale: this.$i18n.locale });
			this.$cookies.set('cookies-necessary', 1);
			this.$cookies.set('cookies-accept-all', 1, {
				// path: '/',
				// maxAge: 60 * 60 * 24,
			});

			// window.location.reload();
		},

		cookiesNecessary () {
			this.$cookies.set('cookies-necessary', 1);
			this.$cookies.set('cookies-gtm', 0);
			this.$cookies.set('cookies-accept-all', 0);

			// window.location.reload();
		},
	},
};

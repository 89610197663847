//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FacebookLogo from '~/assets/img/icons/FacebookLogo';
import YoutubeLogo from '~/assets/img/icons/YoutubeLogo';
import InstagramLogo from '~/assets/img/icons/InstagramLogo';
import TiktokLogo from '~/assets/img/icons/TiktokLogo';
import SpotifyLogo from '~/assets/img/icons/SpotifyLogo';
import Cross from '~/assets/img/icons/Cross';
import HamburgerMenu from '~/assets/img/icons/HamburgerMenu';
import { mapState } from 'vuex';

export default {
	components: { HamburgerMenu, Cross, SpotifyLogo, TiktokLogo, InstagramLogo, YoutubeLogo, FacebookLogo },
	data () {
		return {
			isMobileNavOpen: false,
			showNavbarBg: false,
			lastScrollPosition: 0,
			isNavOnTop: true,
			isNotNavTransparent: this.$router.currentRoute.path !== '/',
		};
	},

	watch: {
		isMobileNavOpen: function () {
			if (this.isMobileNavOpen) {
				document.documentElement.style.overflow = 'hidden';
				return;
			}

			document.documentElement.style.overflow = 'auto';
		},

		$route () {
			if (!this.$router.currentRoute.query.slide) {
				this.$store.dispatch('footer/fetchShowNavbar', true);
				this.showNavbarBg = this.$router.currentRoute.path !== '/';
				this.isNavOnTop = true;
				this.isNotNavTransparent = this.$router.currentRoute.path !== '/';
			}
		},
	},

	computed: mapState({
		showNavbar: (state) => state.footer.showNavbar,
	}),

	mounted () {
		window.addEventListener('scroll', this.onScroll);
	},

	beforeDestroy () {
		window.removeEventListener('scroll', this.onScroll);
	},

	methods: {
		onLogoClick () {
			if (this.$router.currentRoute.fullPath !== '/') {
				this.$router.push('/');
			}
			else {
				window.scrollTo(0, 0);
			}
		},

		onLinkClick (link) {
			this.toggleNavMenuClose();
			if (this.$router.currentRoute.fullPath === `/#${ link }`) {
				document.getElementById(link).scrollIntoView({ behavior: 'smooth' });
			}
		},

		toggleNavMenu () {
			this.isMobileNavOpen = !this.isMobileNavOpen;
		},

		toggleNavMenuClose () {
			this.isNavOnTop = true;
			this.isMobileNavOpen = false;
		},

		toggleLocale () {
			if (this.$i18n.locale === 'cs') {
				window.location.href = 'https://' + this.$config.pageUrl.sk;
			}
			else {
				window.location.href = 'https://' + this.$config.pageUrl.cs;
			}
		},

		onScroll () {
			let st = window.pageYOffset || document.documentElement.scrollTop;
			if (st < 60) {
				this.isNavOnTop = true;
				this.showNavbarBg = false;
			}
			else if (st > this.lastScrollPosition) {
				this.$store.dispatch('footer/fetchShowNavbar', false);
				this.isNavOnTop = false;
				this.showNavbarBg = true;
			}
			else {
				this.$store.dispatch('footer/fetchShowNavbar', true);
				this.isNavOnTop = false;
			}
			this.lastScrollPosition = st <= 0 ? 0 : st;
		},
	},
};

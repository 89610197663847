/* eslint no-shadow: ["error", { "allow": ["state"] }]*/
import Vue from 'vue';

export const state = () => ({
	partyItem: {
		description: '',
		id: null,
		link: '',
		pathToImg: {
			alt: '',
			contentType: '',
			path: '',
		},
		previewImg: {
			alt: '',
			contentType: '',
			path: '',
		},
		resizeImg: {
			alt: '',
			contentType: '',
			path: '',
		},
		slug: '',
		title: '',
		type: '',
	},

	invite: {
		preview: '',
		pdf: {
			path: '',
			contentType: '',
			alt: '',
		},
	},

	inviteForm: {
		inviteName: '',
		inviteWhen: '',
		inviteWhere: '',
		inviteNote: '',
		inviteWho: '',
	},
});

export const mutations = {
	UPDATE_FIELD_PARTY_ITEM (state, payload) {
		state.partyItem = payload;
	},

	UPDATE_FIELD_INVITE_FORM (state, payload) {
		Vue.set(state.inviteForm, payload.fieldName, payload.updatedValue);
	},

	UPDATE_FIELD_INVITE (state, payload) {
		state.invite = payload;
	},
};

import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _368e5839 = () => interopDefault(import('../pages/aktuality/index.vue' /* webpackChunkName: "pages/aktuality/index" */))
const _8b13a30a = () => interopDefault(import('../pages/chystam-party/index.vue' /* webpackChunkName: "pages/chystam-party/index" */))
const _6bef8fa0 = () => interopDefault(import('../pages/cookies/index.vue' /* webpackChunkName: "pages/cookies/index" */))
const _1494164e = () => interopDefault(import('../pages/party-cepice/index.vue' /* webpackChunkName: "pages/party-cepice/index" */))
const _d60ae20a = () => interopDefault(import('../pages/vychytavky/index.vue' /* webpackChunkName: "pages/vychytavky/index" */))
const _34d7b6f1 = () => interopDefault(import('../pages/aktuality/_slug.vue' /* webpackChunkName: "pages/aktuality/_slug" */))
const _8e80e59a = () => interopDefault(import('../pages/chystam-party/_slug.vue' /* webpackChunkName: "pages/chystam-party/_slug" */))
const _d978249a = () => interopDefault(import('../pages/vychytavky/_slug.vue' /* webpackChunkName: "pages/vychytavky/_slug" */))
const _89434b9e = () => interopDefault(import('../pages/chystam-party/_party/_item.vue' /* webpackChunkName: "pages/chystam-party/_party/_item" */))
const _fbeb04a0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aktuality",
    component: _368e5839,
    name: "aktuality___cs"
  }, {
    path: "/aktuality",
    component: _368e5839,
    name: "aktuality___sk"
  }, {
    path: "/chystam-party",
    component: _8b13a30a,
    name: "chystam-party___cs"
  }, {
    path: "/chystam-party",
    component: _8b13a30a,
    name: "chystam-party___sk"
  }, {
    path: "/cookies",
    component: _6bef8fa0,
    name: "cookies___cs"
  }, {
    path: "/cookies",
    component: _6bef8fa0,
    name: "cookies___sk"
  }, {
    path: "/party-cepice",
    component: _1494164e,
    name: "party-cepice___cs"
  }, {
    path: "/party-cepice",
    component: _1494164e,
    name: "party-cepice___sk"
  }, {
    path: "/vychytavky",
    component: _d60ae20a,
    name: "vychytavky___cs"
  }, {
    path: "/vychytavky",
    component: _d60ae20a,
    name: "vychytavky___sk"
  }, {
    path: "/aktuality/:slug",
    component: _34d7b6f1,
    name: "aktuality-slug___cs"
  }, {
    path: "/aktuality/:slug",
    component: _34d7b6f1,
    name: "aktuality-slug___sk"
  }, {
    path: "/chystam-party/:slug?",
    component: _8e80e59a,
    name: "chystam-party-slug___cs"
  }, {
    path: "/chystam-party/:slug?",
    component: _8e80e59a,
    name: "chystam-party-slug___sk"
  }, {
    path: "/vychytavky/:slug",
    component: _d978249a,
    name: "vychytavky-slug___cs"
  }, {
    path: "/vychytavky/:slug",
    component: _d978249a,
    name: "vychytavky-slug___sk"
  }, {
    path: "/chystam-party/:party?/:item",
    component: _89434b9e,
    name: "chystam-party-party-item___cs"
  }, {
    path: "/chystam-party/:party?/:item",
    component: _89434b9e,
    name: "chystam-party-party-item___sk"
  }, {
    path: "/",
    component: _fbeb04a0,
    name: "index___cs"
  }, {
    path: "/",
    component: _fbeb04a0,
    name: "index___sk"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
